import * as React from "react"
import { StaticQuery, graphql } from "gatsby"

function createMarkup(innerHtml) {
  return { __html: innerHtml };
}

export function Announcement() {
  return (
    <StaticQuery
      query={graphql`
      query {
        websiteInfo {
          announcement
        }
      }
      `}
      render={data => (
        <div dangerouslySetInnerHTML={createMarkup(data.websiteInfo.announcement)} align="center" />
      )}
    />
  )
}