import * as React from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faFacebookMessenger, faInstagramSquare} from '@fortawesome/free-brands-svg-icons'
import {
  footerStyle,
  copyright,
  links,
  footerNavList,
  footerNavListItem,
  socialMedia
} from "./footer.module.css"

export function Footer() {
  return (
    <footer className={footerStyle}>
      <div className={socialMedia}>
        <FontAwesomeIcon icon={faFacebook} /> <a href="https://www.facebook.com/groups/2671274086436171/">Our Facebook Group</a><br />
        <FontAwesomeIcon icon={faFacebook} /> <a href="https://www.facebook.com/brokenwindowcreations">Our Facebook Page</a><br />
        <FontAwesomeIcon icon={faFacebookMessenger} /> <a href="https://m.me/brokenwindowcreations">Message us on Facebook Messenger</a><br />
        <FontAwesomeIcon icon={faInstagramSquare} /> <a href="https://www.instagram.com/broken_window_creations/">Our Instagram Account</a><br />
        Phone:  479-802-4857<br />
        Email:  <a href="sales@bwcreations.store">sales@bwcreations.store</a><br />
      </div>
      <nav className={links} aria-label="footer">
        <ul className={footerNavList}>
          <li className={footerNavListItem}>
            <a href="/policies/policies">Return and Refund Policies</a>
          </li>
          <li className={footerNavListItem}>
            <a href="/policies/shipping">Shipping Policy</a>
          </li>
          <li className={footerNavListItem}>
            <a href="/policies/tos">Terms of Service</a>
          </li>
        </ul>
      </nav>
      <div className={copyright}>
        Copyright &copy; {new Date().getFullYear()} Broken Window Creations · All rights reserved
      </div>
    </footer>
  )
}
