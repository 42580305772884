import { Link } from "gatsby"
import * as React from "react"
import slugify from "@sindresorhus/slugify"
import { navStyle, navLink, activeLink } from "./navigation.module.css"

// Set allowable product types, vendors, etc. here

export function Navigation({ className }) {

  const productTypes = ["Home Decor", "Signs", "Door Hangers", "Crafts", "Seasonal", "DIY Crafts", "Toys", "Accessories"];

  return (
    <nav className={[navStyle, className].join(" ")}>
      {productTypes.map((name) => (
        <Link
          key={name}
          className={navLink}
          to={`/products/${slugify(name)}`}
          activeClassName={activeLink}
        >
          {name}
        </Link>
      ))}
    </nav>
  )
}
